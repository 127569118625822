import React from 'react'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

// CSS
import theme from 'styles/theme'
import { P, H2, SingleGradient, StyledBackgroundImage } from 'components/StyledComponents'

// Third Party
import styled from 'styled-components'

const Wrapper = styled.div`
  min-height: 526px;

  ${H2} {
    width: 50%;
    pointer-events: none;
  }

  @media (max-width: 992px) {
    min-height: 360px;

    ${H2} {
      width: 100%;
    }
  }

  @media (max-width: 576px) {
    padding-left: 10px;
  }

  @media (max-width: 350px) {
    min-height: 200px;
    padding-left: 10px;

    ${H2} {
      font-size: ${theme.fontSizeXXML}px;
    }

    ${P} {
      font-size: ${theme.fontSizeL}px;
    }
  }
`

const Spacer = styled.div`
  @media (max-width: 350px) {
    padding-bottom: 10px !important;
  }
`

const ExtraButton = styled.div`
  width: 277px;
  height: 50px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  ${P} {
    line-height: 18px;
  }

  @media (max-width: 350px) {
    ${P} {
      font-size: ${theme.fontSizeM}px;
    }
  }
`

const TitleBannerImage = ({ image, title, breadCrumbTitle, pageContext, className, extraButton}) => (
  <Wrapper className={`${className ? `${className}` : ``}`}>
    <StyledBackgroundImage className="position-absolute col-12" fluid={image} alt="" />
    <SingleGradient className="h-100 position-absolute col-12" />
    <div className="container">
      <H2 opacity={0.5} className="font-size-xxxl font-weight-xl color-text-light">{title}</H2>
      <Breadcrumb className="pb-5" crumbSeparator={" > "} crumbs={pageContext.breadcrumb.crumbs} crumbLabel={breadCrumbTitle} />
      {extraButton ? (
        <ExtraButton className="px-3 mt-4 color-background-main d-flex align-items-center">
          <a href={`tel:${extraButton.phonenumber}`} className="d-flex align-items-center">
            <div>
              <P className="font-size-sm font-weight-xl color-text-light">{extraButton.text}</P>
              <P className="font-size-sm font-weight-xl color-text-light">{extraButton.sub_text}</P>
            </div>
            <P className="font-size-l pl-3 font-weight-xl color-text-striking">{extraButton.phonenumber}</P>
          </a>
        </ExtraButton>
      ) : (
        <Spacer className="pb-5" />
      )}
    </div>
  </Wrapper>
)

export default TitleBannerImage