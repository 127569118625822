import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// CSS

// Components
import TitleBannerImage from 'components/TitleBannerImage'
import Layout from 'components/Layout'
import SEO from 'components/SEO'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const Content = styled.div`
  h3 {
    font-weight: 900;
    padding-top: 20px;
  }
`

const PrivacyDeclaration = ({ pageContext }) => {
  const {
    privacy: {
      title,
      acf: {
        image: {
          localFile: {
            childImageSharp: {
              fluid: bannerImage
            }
          }
        },
        content,
        h1_tag: h1Tag
      },
      yoast_meta: yoast
    }
  } = useStaticQuery(graphql`{
    privacy: wordpressPage(wordpress_id: {eq: 383}) {
      title
      acf {
        image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        content,
        h1_tag
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }`)

  return (
    <Layout subFooter>
      <SEO yoast={{ meta: yoast }} />
      <h1 className="d-none">{h1Tag}</h1>
      <TitleBannerImage image={bannerImage} pageContext={pageContext} breadCrumbTitle={title} title={title} className="row position-relative d-flex align-items-end" />
      <Content className="container py-5">
        {parse(content)}
      </Content>
    </Layout>
  )
}

export default PrivacyDeclaration